// trains_order_orderBookingAmountCalculation 预定页面计算金额

const __request = require(`@/lib/data-service/trains/__request/__request_contentType_json`);

// 接口文档地址：
export default function trains_order_orderBookingAmountCalculation(pParameter) {
  let params = {}
  if (!pParameter) pParameter = {};
  /*const doorToDoor = pParameter.doorToDoor || ''
  const headCounts = pParameter.headCounts || ''
  const insuracneFlag = pParameter.insuracneFlag
  const insurancePrice = pParameter.insurancePrice || 0
  const ticketPrice = pParameter.ticketPrice || ''*/
  params.method = 'POST'
  params.urlSuffix = '/trains/order/orderBookingAmountCalculation'
  params.data = pParameter
  /*params.data = {
    doorToDoor,
    headCounts,
    insuracneFlag,
    insurancePrice,
    ticketPrice
  }*/
  return new Promise(function(resolve, reject) {
    resolve(__request(params))
  })
}

