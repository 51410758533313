// trains_order_checkAccountV2

const __request = require(`@/lib/data-service/trains/__request/__request_contentType_json`);

// 接口文档地址：
export default function trains_order_checkAccountV2(pParameter) {
    let params = {}
    if (!pParameter) pParameter = {};
    const password = pParameter.password || ''
    const userName = pParameter.userName || ''
    params.method = 'POST'
    params.urlSuffix = '/trains/order/checkAccountV2'
    params.data = {
        password,
        userName
    }
    return new Promise(function(resolve, reject) {
        resolve(__request(params))
    })
}
