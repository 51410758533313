// trains_order_confirmTheCheck 确认核验

const __request = require(`@/lib/data-service/trains/__request/__request_contentType_json`);

// 接口文档地址： http://219.128.52.2:8061/mock/8/trains/common/queryRailwayTimetable
export default function trains_order_confirmTheCheck(pParameter) {
    let params = {}
    if (!pParameter) pParameter = {};
    params.method = 'POST'
    params.urlSuffix = '/trains/order/confirmTheCheck'
    params.data = pParameter
    return new Promise(function(resolve, reject) {
        resolve(__request(params))
    })
}
