import Q from 'q';
import trains_product_getOrderInsuranceInfo from '@/lib/data-service/trains/trains_product_getOrderInsuranceInfo'
import trains_common_getOrderBaseData from '@/lib/data-service/trains/trains_common_getOrderBaseData'
import trains_order_orderBookingAmountCalculation from '@/lib/data-service/trains/trains_order_orderBookingAmountCalculation'
import trains_order_orderBookingCheckout from '@/lib/data-service/trains/trains_order_orderBookingCheckout'
import trains_order_orderBookingPolling from '@/lib/data-service/trains/trains_order_orderBookingPolling'
import trains_order_checkAccountV2 from '@/lib/data-service/trains/trains_order_checkAccountV2'
import web_common_tourOrder_getTravelAccount from '@/lib/data-service/default/web_common_tourOrder_getTravelAccount' // 获取旅游经理的信息
import trains_order_applyForCancel from '@/lib/data-service/trains/trains_order_applyForCancel'
import trains_order_confirmTheCheck from '@/lib/data-service/trains/trains_order_confirmTheCheck'
import web_common_getUserMsg from '@/lib/data-service/default/web_common_getUserMsg'
import moment from 'moment'
// import Cookies from 'js-cookie'
export default {
  data () {
    const validateCard = (rule, value, callback) => {
      /*function IdCodeValid (code) {
        //身份证号合法性验证
        //支持15位和18位身份证号
        //支持地址编码、出生日期、校验位验证
        const city = {11: '北京',12: '天津',13: '河北',14: '山西',15: '内蒙古',21: '辽宁',22: '吉林',23: '黑龙江 ',31: '上海',32: '江苏',33: '浙江',34: '安徽',35: '福建',36: '江西',37: '山东',41: '河南',42: '湖北 ',43: '湖南',44: '广东',45: '广西',46: '海南',50: '重庆',51: '四川',52: '贵州',53: '云南',54: '西藏 ',61: '陕西',62: '甘肃',63: '青海',64: '宁夏',65: '新疆',71: '台湾',81: '香港',82: '澳门',91: '国外 '};
        let row = {
          'pass': true,
          'msg': '验证成功'
        };
        if (!code || !/^\d{6}(18|19|20)?\d{2}(0[1-9]|1[012])(0[1-9]|[12]\d|3[01])\d{3}(\d|[xX])$/.test(code)) {
          row = {
            'pass': false,
            'msg': '身份证号格式错误'
          };
        } else if (!city[code.substr(0,2)]) {
          row = {
            'pass': false,
            'msg': '身份证号地址编码错误'
          };
        } else {
          //18位身份证需要验证最后一位校验位
          if (code.length === 18) {
            code = code.split('');
            //∑(ai×Wi)(mod 11)
            //加权因子
            const factor = [ 7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2 ];
            //校验位
            const parity = [ 1, 0, 'X', 9, 8, 7, 6, 5, 4, 3, 2 ];
            let sum = 0;
            let ai = 0;
            let wi = 0;
            for (let i = 0; i < 17; i++) {
              ai = code[i];
              wi = factor[i];
              sum += ai * wi;
            }
            if (parity[sum % 11] !== code[17].toUpperCase()) {
              row = {
                'pass': false,
                'msg': '身份证号校验位错误'
              };
            }
          }
        }
        return row;
      } */
      let cardType = 1
      for (let i = 0, l = this.trainsOrderPassengers.length; i < l; i++) {
        if (this.trainsOrderPassengers[i].passengerCardNumber === value) {
          cardType = this.trainsOrderPassengers[i].passengerCardType
        }
      }
      if (cardType === 1) {
        const regIdCard = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
        if (!regIdCard.test(value)) {
          callback(new Error('身份证号填写有误'))
        } else {
          callback()
        }
      } else {
        callback()
      }
    };
    const validatePhone = (rule, value, callback) => {
      const regExp = /^(?:13\d|14[5-9]|15\d|166|17\d|18\d|19[0|8|9]|106)-?\d{3,8}$/
      if (regExp.test(value)) {
        callback()
      } else {
        callback(new Error('不符合手机号码格式'))
      }
    };
    const validateEmail = (rule, value, callback) => {
      if (value === '') {
        callback()
        return
      }
      const regExp = /^([a-zA-Z]|[0-9])(\w|\-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/
      if (regExp.test(value)) {
        callback()
      } else {
        callback(new Error('邮件格式不正确'))
      }
    };
    return {
      loading: false,
      loadingText: '',
      checkedRead: false,
      expressInfoVo: {
        expressAddress: '',
        expressAmount: '',
        expressCity: '',
        expressCityCode: '',
        expressCityName: '',
        orderNo: '',
        receiveName: '',
        receivePhone: '',
        receiveZipCode: ''
      },
      orderBookingInfoVo: {
        acceptNoSeat: 1, // 接受无座
        adultCounts: 1, // 成人人数
        serviceCharge: 0, // 代购费 服务费
        arriveDate: '', // 到达日期
        buyTicketsType: 0,
        childrenCounts: 0, // 儿童人数
        chooseSeat: '', // 选座

        fromStationCode: '',
        fromStationName: '',
        headCounts: 1,
        insuracneFlag: 0,
        insuranceAmount: 0,
        insuranceName: '',
        insurancePrice: 0,
        orderAmount: 0,
        orderNo: '',
        orderSource: 1,
        buyerContactEmail: '',
        buyerContactName: '',
        buyerContactPhone: '',
        remark: '',
        seatAmount: 0,
        seatCode: '',
        seatName: '',
        seatPrice: 0,
        startDate: '',
        toStationCode: '',
        toStationName: '',
        trainInnerCode: '',
        trainNo: '',
        fromTime: '',
        toTime: '',

        fromStationType: '',
        toStationType: '',
        trainStartDate: '',
        canChooseSeat: true,
        numberOfSeats: 5,
        spanTime: '',
        arriveTime: '',
        ptContactName: '',
        ptContactPhone: ''
      },
      sexList: [],
      cardType: [],
      nationalities: [], // 国籍列表
      maxNum: 5,
      trainsOrderPassengers: [
        {
          passengerBirthday: '',
          passengerCardNumber: '', // 证件号
          passengerCardType: 1,
          passengerCountry: '', //国籍
          passengerExpireDate: '', // 证件有效期
          passengerName: '', // 乘客姓名
          passengerSeq: '', // 顺序码
          passengerType: 1, // 乘客类型
          passengerSex: 1, // 性别
          passengerIndex: '', // 大人 1+index  小孩 2+index
          userType: 1, // 1是大人， 2是小孩
        }
      ],
      insuranceList: [], // 保险列表
      insuranceInfoVos: {
        insuranceProId: ''
      },
      insuranceProId: '',
      insurance: '',
      insuranceDesc: '',
      priceParams: {
        doorToDoor: 2,
        headCounts: 1,
        insuracneFlag: 0,
        insurancePrice: '',
        ticketPrice: ''
      },
      businessSeatArr: [
        [{label: 'A', value: '1A', active: false}, {label: 'C', value: '1C', active: false}, {
          label: 'F',
          value: '1F',
          active: false
        }],
        [{label: 'A', value: '2A', active: false}, {label: 'C', value: '2C', active: false}, {
          label: 'F',
          value: '2F',
          active: false
        }]
      ], // 特等座选位
      firstSeatArr: [
        [{label: 'A', value: '1A', active: false}, {label: 'C', value: '1C', active: false}, {
          label: 'D',
          value: '1D',
          active: false
        }, {label: 'F', value: '1F', active: false}],
        [{label: 'A', value: '2A', active: false}, {label: 'C', value: '2C', active: false}, {
          label: 'D',
          value: '2D',
          active: false
        }, {label: 'F', value: '2F', active: false}]
      ], // 一等座选位
      secondSeatArr: [
        [{label: 'A', value: '1A', active: false}, {label: 'B', value: '1B', active: false}, {
          label: 'C',
          value: '1C',
          active: false
        }, {label: 'D', value: '1D', active: false}, {label: 'F', value: '1F', active: false}],
        [{label: 'A', value: '2A', active: false}, {label: 'B', value: '2B', active: false}, {
          label: 'C',
          value: '2C',
          active: false
        }, {label: 'D', value: '2D', active: false}, {label: 'F', value: '2F', active: false}]
      ], // 二等座选位
      seatArr: [],
      seatList: [], // 选中的座位
      passengerRule: {
        passengerName: [
          {required: true, message: '请输入姓名', trigger: 'blur'},
        ],
        passengerCardNumber: [
          {required: true, message: '请输入证件号码', trigger: 'blur'},
          {validator: validateCard, trigger: 'blur'}
        ],
        passengerSex: [
          {required: true, message: '请选择性别', trigger: 'change'},
        ],
        passengerCountry: [
          {required: true, message: '请选择国籍', trigger: 'change'},
        ],
        passengerBirthday: [
          {required: true, message: '请选择出生日期', trigger: 'change'},
        ],
        passengerExpireDate: [
          {required: true, message: '请选择证件有效期截止日期', trigger: 'change'},
        ]
      },
      contactRule: {
        buyerContactName: [
          {required: true, message: '请输入联系人姓名', trigger: 'blur'},
        ],
        buyerContactPhone: [
          {required: true, message: '请输入手机号码', trigger: 'blur'},
          {validator: validatePhone, trigger: 'blur'}
        ],
        buyerContactEmail: [
          {validator: validateEmail, trigger: 'blur'}
        ]
      },
      userInfoVo: {
        password: '',
        userName: ''
      },
      isLogin: false,
      loginParams: {
        password: '',
        userName: ''
      },
      loginRule: {
        password: [
          {required: true, message: '请输入密码', trigger: 'blur'},
        ],
        userName: [
          {required: true, message: '请输入用户名', trigger: 'blur'},
        ]
      },
      loginBoxShow: false,
      procurementRuleShow: false,
      procurementRuleText: '',
      travelAccount: {},
      showMore: false,
      showRuleMore: false,
      // orderAmount: 0
      searchFormStationCode: '',
      searchToStationCode: '',
      userVo: {}, // 用户信息
      orderBookingPolling: '',

      orderPopShow: false,
      loadingNum: 0,

      passengersPopShow: false,
      passengerList: [],

      keepMinute: '', // 循环计时3分钟方法
      nowMinute: 0, // 累计分钟
      showContinueBtn: false, //
      orderNo: '',

      failPopShow: false, // 失败弹窗
      failMsg: '', // 失败弹窗提示

      warningPopShow: false, // 软改硬座弹窗
    }
  },
  components: {},
  created () {
    document.documentElement.scrollTop = 0;
    this.orderBookingInfoVo.fromStationCode = this.$route.query.fromStationCode
    this.orderBookingInfoVo.fromStationName = this.$route.query.fromStationName
    this.orderBookingInfoVo.fromStationType = this.$route.query.fromStationType // 无
    this.orderBookingInfoVo.fromTime = this.$route.query.fromTime
    this.orderBookingInfoVo.toStationCode = this.$route.query.toStationCode
    this.orderBookingInfoVo.toStationName = this.$route.query.toStationName
    this.orderBookingInfoVo.toStationType = this.$route.query.toStationType
    this.orderBookingInfoVo.trainNo = this.$route.query.trainNo
    this.orderBookingInfoVo.trainCode = this.$route.query.trainCode
    this.orderBookingInfoVo.toTime = this.$route.query.toTime
    this.orderBookingInfoVo.trainStartDate = this.$route.query.trainStartDate

    this.orderBookingInfoVo.arriveDate = moment(this.$route.query.arriveDate).format('YYYY-MM-DD')
    // this.orderBookingInfoVo.arriveTime = this.$route.query.toTime
    this.orderBookingInfoVo.startDate = this.$route.query.fromDate
    this.orderBookingInfoVo.arriveTime = this.$route.query.arriveDate
    // backSeat
    // orderNo
    // orderSource
    // this.orderBookingInfoVo.agentTicketPrice = parseFloat(this.$route.query.serviceCharge)
    this.orderBookingInfoVo.serviceCharge = parseFloat(this.$route.query.serviceCharge)
    // this.orderBookingInfoVo.expressFee = parseInt(this.$route.query.expressFee)

    this.orderBookingInfoVo.seatPrice = parseFloat(this.$route.query.seatPrice)
    this.orderBookingInfoVo.seatCode = this.$route.query.seatCode
    this.orderBookingInfoVo.seatName = this.$route.query.seatName
    this.orderBookingInfoVo.canChooseSeat = parseInt(this.$route.query.canChooseSeat) === 1
    this.orderBookingInfoVo.numberOfSeats = parseInt(this.$route.query.numberOfSeats)
    this.orderBookingInfoVo.spanTime = this.$route.query.spanTime

    this.searchFormStationCode = this.$route.query.searchFormStationCode
    this.searchToStationCode = this.$route.query.searchToStationCode

    if (this.orderBookingInfoVo.numberOfSeats === 3) {
      this.seatArr = this.businessSeatArr
    } else if (this.orderBookingInfoVo.numberOfSeats === 4) {
      this.seatArr = this.firstSeatArr
    } else {
      this.seatArr = this.secondSeatArr
    }

    web_common_getUserMsg().then(res => {
      this.userVo = res.userVo

      // 获取cookie保存的12306账号
      // let userInfoVo = Cookies.get('userInfoVo')
      // let trainLoginParams = Cookies.get('trainLoginParams')
      let userInfoVo = localStorage.getItem('userInfoVo')
      let trainLoginParams = localStorage.getItem('trainLoginParams')
      if (userInfoVo !== null && trainLoginParams !== null) {
        let userInfo = JSON.parse(userInfoVo)
        if (userInfo.userId === this.userVo.id) {
          this.userInfoVo = JSON.parse(userInfoVo)
          this.loginParams = JSON.parse(trainLoginParams)
          this.isLogin = true
        }
      }
    })


    this.orderBookingAmountCalculation()

  },
  mounted () {
    this.getInsuranceInfo()
    this.getOrderBaseData()
    this.getTravelAccount()
    this.scrollToTop()
    window.addEventListener('scroll', this.scrollToTop)
  },
  activated () {
  },
  deactivated() {},
  destroyed () {
    this.orderBookingPolling = ''
    window.removeEventListener('scroll', this.scrollToTop)
    clearInterval(this.keepMinute)
  },
  watch: {
    trainsOrderPassengers(val) {
      this.orderBookingAmountCalculation()
    }
  },
  computed: {},
  filters: {
    trainStartFilter(val) {
      return moment(val).format('YYYY年MM月DD日')
    },
    getWeekFilter (date) { // 参数时间戳
      let week = moment(date).day()
      switch (week) {
        case 1:
          return '周一'
        case 2:
          return '周二'
        case 3:
          return '周三'
        case 4:
          return '周四'
        case 5:
          return '周五'
        case 6:
          return '周六'
        case 0:
          return '周日'
      }
    },
  },
  methods: {
    toHome() {
      this.$router.push({
        name: 'distributor-train-front-home'
      })
    },
    scrollToTop() {
      let leftBox = this.$refs.leftBox
      let rightBox = this.$refs.rightBox
      let top = leftBox.getBoundingClientRect().top
      let leftBoxHeight = leftBox.clientHeight
      let rightBoxHeight = rightBox.clientHeight
      // position: absolute; fixed
      if (leftBoxHeight > rightBoxHeight && top <= 0) {
        rightBox.style.position = 'absolute'
        rightBox.style.right = 0
        if (leftBoxHeight < Math.abs(top) + rightBoxHeight) {
          rightBox.style.top = (leftBoxHeight - rightBoxHeight) + 'px'
        } else {
          rightBox.style.top = parseInt(Math.abs(top)) + 'px'
        }
      } else {
        rightBox.style.position = 'relative'
        rightBox.style.top = 0
      }
    },
    showMoreExplain() {
      let showMore = this.showMore
      this.showMore = !showMore
      if (!showMore) {
        window.scrollTo(0, document.documentElement.scrollTop - 10)
      }
    },
    showRuleMoreExplain() {
      let showMore = this.showRuleMore
      this.showRuleMore = !showMore
      if (!showMore) {
        window.scrollTo(0, document.documentElement.scrollTop - 10)
      }
    },
    getTravelAccount() {
      web_common_tourOrder_getTravelAccount().then(res => {
        this.orderBookingInfoVo.ptContactName = res.travelAccountManager
        this.orderBookingInfoVo.ptContactPhone = res.phone
      })
    },
    changeCardType(index, val) {},
    addAdult() {
      if (this.trainsOrderPassengers.length >= this.maxNum) {
        return
      }
      this.trainsOrderPassengers.push({
        passengerBirthday: '',
        passengerCardNumber: '', // 证件号
        passengerCardType: 1,
        passengerCountry: '', //国籍
        passengerExpireDate: '', // 证件有效期
        passengerName: '', // 乘客姓名
        passengerSeq: '', // 顺序码
        passengerType: 1, // 乘客类型
        passengerSex: 1, // 性别
        passengerIndex: '', // 大人 1+index  小孩 2+index
        userType: 1, // 1是大人， 2是小孩
      })
      this.userSort()
      this.orderBookingInfoVo.adultCounts++
      this.orderBookingInfoVo.headCounts++
    },
    addChild() {
      if (this.trainsOrderPassengers.length >= this.maxNum) {
        return
      }
      this.trainsOrderPassengers.push({
        passengerBirthday: '',
        passengerCardNumber: '', // 证件号
        passengerCardType: 1,
        passengerCountry: '', //国籍
        passengerExpireDate: '', // 证件有效期
        passengerName: '', // 乘客姓名
        passengerSeq: '', // 顺序码
        passengerType: 2, // 乘客类型
        passengerSex: 1, // 性别
        passengerIndex: '', // 大人 1+index  小孩 2+index
        userType: 2, // 1是大人， 2是小孩
      })
      this.userSort()
      this.orderBookingInfoVo.childrenCounts++
      this.orderBookingInfoVo.headCounts++
    },
    userSort() {
      let adultNum = 0;
      let childNum = 0;
      this.trainsOrderPassengers.forEach(value => {
        if (value.passengerType === 1) {
          value.passengerIndex = '1' + adultNum
          adultNum++
        } else {
          value.passengerIndex = '2' + childNum
          childNum++
        }
      })
    },
    delThisUser(val, index) {
      let adult = 0
      let children = 0
      for (let i = 0, l = this.trainsOrderPassengers.length; i < l; i++) {
        if (this.trainsOrderPassengers[i].userType === 1) {
          adult++
        } else {
          children++
        }
      }
      if (val.userType === 1 && adult === 1) {
        this.$message({
          message: '必须至少含有一位成人乘客',
          type: 'warning'
        })
        return
      }
      this.trainsOrderPassengers.splice(index, 1)
      this.userSort()
      if (val.passengerType === 2) {
        this.orderBookingInfoVo.childrenCounts--
      } else {
        this.orderBookingInfoVo.adultCounts--
      }
      this.orderBookingInfoVo.headCounts--

      // 如果座位有选多了，则去掉一个座位
      if (this.trainsOrderPassengers.length < this.seatList.length) {
        let sIndex = this.seatList.length - 1
        let value = this.seatList[sIndex]
        this.seatList.splice(sIndex, 1)
        for (let i = 0, l = this.seatArr.length; i < l; i++) {
          let seatItem = this.seatArr[i]
          for (let m = 0, n = seatItem.length; m < n; m++) {
            if (seatItem[m].value === value) {
              this.seatArr[i][m].active = false
              break
            }
          }
        }
      }
    },
    clearThisUser(val, index) {
      let params = {}
      if (val.passengerType === 1) {
        params = {
          passengerBirthday: '',
          passengerCardNumber: '', // 证件号
          passengerCardType: 1,
          passengerCountry: '', //国籍
          passengerExpireDate: '', // 证件有效期
          passengerName: '', // 乘客姓名
          passengerSeq: '', // 顺序码
          passengerType: 1, // 乘客类型
          passengerSex: 1, // 性别
          passengerIndex: '', // 大人 1+index  小孩 2+index
          userType: 1, // 1是大人， 2是小孩
        }
      } else {
        params = {
          passengerBirthday: '',
          passengerCardNumber: '', // 证件号
          passengerCardType: 1,
          passengerCountry: '', //国籍
          passengerExpireDate: '', // 证件有效期
          passengerName: '', // 乘客姓名
          passengerSeq: '', // 顺序码
          passengerType: 2, // 乘客类型
          passengerSex: 1, // 性别
          passengerIndex: '', // 大人 1+index  小孩 2+index
          userType: 2, // 1是大人， 2是小孩
        }
      }
      this.trainsOrderPassengers.splice(index, 1, params)
      this.userSort()
    },
    getInsuranceInfo() {
      Q.when().then(() => {
        return trains_product_getOrderInsuranceInfo()
      }).then((res) => {
        let insuranceList = res.data
        insuranceList.forEach((value, index) => {
          value.label = `${value.insurancePrice}元/份 ${value.insuranceRemark}`
          if (index === 0) {
            this.insuranceProId = value.id
            this.insuranceDesc = value.insuranceDesc
          }
        })
        this.insuranceList = res.data
      })
    },
    changeInsuranceDesc(val) {
      for (let i = 0, l = this.insuranceList.length; i < l; i++) {
        if (val === this.insuranceList[i].id) {
          this.insuranceDesc = this.insuranceList[i].insuranceDesc
          // 如果当前是选定购买保险的情况
          if (this.orderBookingInfoVo.insuracneFlag === 1) {
            this.insuranceInfoVos.insuranceProId = val
            this.orderBookingInfoVo.insurancePrice = this.insuranceList[i].insurancePrice // 单价
            this.orderBookingInfoVo.insuranceName = this.insuranceList[i].insuranceClass.name
            this.orderBookingAmountCalculation()
          }
          break
        }
      }
    },
    addInsurance() {
      if (this.insuranceInfoVos.insuranceProId === '') {
        if (this.insuranceProId === '') {
          return
        }
        this.orderBookingInfoVo.insuracneFlag = 1
        for (let i = 0, l = this.insuranceList.length; i < l; i++) {
          if (this.insuranceProId === this.insuranceList[i].id) {
            this.insuranceInfoVos.insuranceProId = this.insuranceProId
            this.orderBookingInfoVo.insurancePrice = this.insuranceList[i].insurancePrice // 单价
            this.orderBookingInfoVo.insuranceName = this.insuranceList[i].insuranceClass.name
            break
          }
        }
      } else {
        this.orderBookingInfoVo.insuracneFlag = 0
        this.insuranceInfoVos = {
          insuranceProId: ''
        }
        this.orderBookingInfoVo.insurancePrice = 0 // 单价
        this.orderBookingInfoVo.insuranceName = ''
      }
      // 计算
      this.orderBookingAmountCalculation()
    },
    getOrderBaseData() {
      Q.when().then(() => {
        return trains_common_getOrderBaseData()
      }).then(res => {
        this.sexList = res.genderTypeMapList
        let cardType = res.passengerCardTypeList
        let childCardIndex = 0
        for (let i = 0, l = cardType.length; i < l; i++) {
          if (cardType[i].code === 2) {
            childCardIndex = i
            break
          }
        }
        cardType.splice(childCardIndex, 1)
        this.cardType = cardType
        this.nationalities = res.nationalities
      })
    },
    orderBookingAmountCalculation() {
      // 保险总价
      if (this.orderBookingInfoVo.insuracneFlag === 1) {
        this.orderBookingInfoVo.insuranceAmount = this.orderBookingInfoVo.insurancePrice * this.trainsOrderPassengers.length
      }
      // 坐席总价
      this.orderBookingInfoVo.seatAmount = this.orderBookingInfoVo.seatPrice * this.trainsOrderPassengers.length
      // 手续费
      // this.orderBookingInfoVo.serviceCharge = this.orderBookingInfoVo.agentTicketPrice * this.trainsOrderPassengers.length
      let priceParams = {
        doorToDoor: 2,
        headCounts: this.orderBookingInfoVo.headCounts,
        insuracneFlag: this.orderBookingInfoVo.insuracneFlag,
        insurancePrice: this.orderBookingInfoVo.insurancePrice,
        ticketPrice: this.orderBookingInfoVo.seatPrice,
        serviceCharge: this.orderBookingInfoVo.serviceCharge
      }
      trains_order_orderBookingAmountCalculation(priceParams).then(res => {
        this.orderBookingInfoVo.orderAmount = res.orderAmount
      })
    },
    handleChooseSeat(index, sIndex, val) {
      if (val.active) { // 取消
        this.seatArr[index][sIndex].active = false
        let seatIndex = this.seatList.indexOf(val.value)
        this.seatList.splice(seatIndex, 1)
      } else { // 选中
        if (this.seatList.length >= this.trainsOrderPassengers.length) {
          return
        }
        this.seatArr[index][sIndex].active = true
        this.seatList.push(val.value)
      }
    },
    submit() {
      if (!this.checkedRead) {
        this.$message({
          message: '请先勾选并同意条款',
          type: 'warning'
        })
        return
      }
      // 判断是否有成人
      let isHaveAdult = false
      for (let i = 0, l = this.trainsOrderPassengers.length; i < l; i++) {
        if (this.trainsOrderPassengers[i].userType === 1) {
          isHaveAdult = true
          break
        }
      }
      if (!isHaveAdult) {
        this.$message({
          message: '必须至少含有一位成人乘客',
          type: 'warning'
        })
        return
      }
      let formList = []
      let formState = []
      this.trainsOrderPassengers.forEach((value, index) => {
        formList.push('form' + index)
        formState.push(-1)
      })
      formList.map((value, index) => {
        // 先循环判断$refs中是数组的表单
        this.$refs[value][0].validate((valid) => {
          valid ? formState[index] = 1 : formState[index] = -1
          if (formState.indexOf(-1) === -1) {
            // 最后判断contactForm表单
            this.$refs['contactForm'].validate((formValid) => {
              if (formValid) {
                // 录入选座
                if (this.orderBookingInfoVo.canChooseSeat) {
                  this.orderBookingInfoVo.chooseSeat = this.seatList.join('')
                }
                // 填入乘客顺序码 + 身份证类型的用户获取生日和性别
                this.trainsOrderPassengers.forEach((value1, index1) => {
                  value1.passengerSeq = index1 + 1
                  if (value1.passengerCardType === 1 && value1.userType === 1) {
                    value1.passengerBirthday = this.getBirth(value1.passengerCardNumber)
                    value1.passengerSex = this.getSex(value1.passengerCardNumber)
                  }
                })
                let params = {
                  // expressInfoVo: this.expressInfoVo,
                  expressInfoVo: null,
                  insuranceInfoVos: this.insuranceInfoVos.insuranceProId === '' ? null : this.insuranceInfoVos,
                  orderBookingInfoVo: this.orderBookingInfoVo,
                  trainsOrderPassengers: this.trainsOrderPassengers,
                  userInfoVo: this.userInfoVo.userName !== '' ? this.userInfoVo : null
                }
                let newParams = Object.assign({}, params)
                if (newParams.userInfoVo !== null) {
                  newParams.orderBookingInfoVo.buyTicketsType = 2
                }
                // 弹出loading
                /*let loading = this.$loading({
                  lock: true,
                  text: '正在预定',
                  spinner: 'el-icon-loading',
                  background: 'rgba(0, 0, 0, 0.7)'
                });*/
                // this.loadingText = '正在预定'
                // this.loading = true
                this.loadingNum = 0
                this.orderPopShow = true
                let _this = this
                this.orderBookingPolling = function () {
                  trains_order_orderBookingPolling({orderNo: this.orderNo}).then(result => {
                    if (this.loadingNum < 96) {
                      this.loadingNum++
                    }
                    if (this.nowMinute >= 3 * 60) {
                      this.showContinueBtn = true
                      clearInterval(this.keepMinute)
                      return
                    }
                    if (result.successful === 0) {
                      setTimeout(() => {
                        if (this.orderBookingPolling === '') {
                          return
                        }
                        this.orderBookingPolling(this.orderNo)
                      }, 500)
                    } else if (result.successful === 1) { // 成功
                      _this.loadingNum = 100
                      setTimeout(() => {
                        _this.orderPopShow = false
                        _this.$router.push({
                          name: 'distributor-train-front-pay',
                          query: {
                            orderNo: _this.orderNo,
                            type: 'reserve',
                          }
                        })
                      }, 500)
                    } else if (result.successful === 2) { // 失败
                      _this.orderPopShow = false
                      _this.failMsg = result.errorMessage
                      _this.failPopShow = true
                      /*_this.$alert(result.errorMessage, '温馨提示', {
                        confirmButtonText: '重新下单',
                        callback: action => {
                          _this.$router.go(-1)
                        }
                      })*/
                      // _this.$message.error(result.msg)
                      /*_this.$router.push({
                        name: 'distributor-train-front-search',
                        query: {
                          fromStationCode: _this.orderBookingInfoVo.fromStationCode,
                          toStationCode: _this.orderBookingInfoVo.toStationCode,
                          time: moment(_this.orderBookingInfoVo.trainStartDate).format('YYYY-MM-DD'),
                        }
                      })*/
                    } else if (result.successful === 3) { // 需核验
                      _this.nowMinute = 0
                      clearInterval(_this.keepMinute)
                      _this.passengersPopShow = true
                      _this.passengerList = result.passengers
                    } else if (result.successful === 4) { // 软改硬座
                      _this.orderPopShow = false
                      _this.loadingNum = 100
                      _this.warningPopShow = true


                      /*_this.$confirm('占座成功。该座位为动车软卧代二等座，是否继续购票？', '温馨提示', {
                        confirmButtonText: '继续购票',
                        cancelButtonText: '取消订单',
                        type: 'warning'
                      }).then(() => {
                        _this.$router.push({
                          name: 'distributor-train-front-pay',
                          query: {
                            orderNo: _this.orderNo,
                            type: 'reserve',
                          }
                        })
                      }).catch(() => {
                        _this.$router.go(-1)
                      })*/
                    }
                  }).catch((result) => {
                    // _this.loading = false
                    _this.orderPopShow = false
                    this.nowMinute = 0
                    _this.$message.error(result.data.msg)
                    /*_this.$router.push({
                      name: 'distributor-train-front-search',
                      query: {
                        fromStationCode: _this.orderBookingInfoVo.fromStationCode,
                        toStationCode: _this.orderBookingInfoVo.toStationCode,
                        time: moment(_this.orderBookingInfoVo.trainStartDate).format('YYYY-MM-DD'),
                      }
                    })*/
                  })
                }
                trains_order_orderBookingCheckout(newParams).then(res => {
                  if (res.code !== '000000') {
                    // loading.close()
                    // this.loading = false
                    this.orderPopShow = false
                    return
                  }
                  let orderNo = res.orderNo
                  this.keepMinute = setInterval(() => {
                    this.nowMinute++
                  }, 1000)
                  this.orderNo = orderNo
                  this.orderBookingPolling()
                }).catch(() => {
                  // loading.close()
                  // this.loading = false
                  this.orderPopShow = false
                })
              }
            })
          }
        })
      })
    },
    handleFailClose() {
      this.failPopShow = false
      this.$router.go(-1)
    },
    keepToBuy() {
      if (this.orderBookingPolling === '') {
        return
      }
      this.showContinueBtn = false
      this.nowMinute = 0
      this.keepMinute = setInterval(() => {
        this.nowMinute++
      }, 1000)
      this.orderBookingPolling()
    },
    cancelOrder() {
      trains_order_applyForCancel({orderNo: this.orderNo}).then(res => {
        this.showContinueBtn = false
        this.orderPopShow = false
        this.$router.go(-1)
      })
    },
    handleClose() {
      this.orderPopShow = false
      this.orderBookingPolling = ''
    },
    getBirth(idCard) {
      let birthday = '';
      if (idCard != null && idCard !== '') {
        if (idCard.length === 15) {
          birthday = '19' + idCard.slice(6,12);
        } else if (idCard.length === 18) {
          birthday = idCard.slice(6,14);
        }
        birthday = birthday.replace(/(.{4})(.{2})/,'$1-$2-');
        //通过正则表达式来指定输出格式为:1990-01-01
      }
      return birthday;
    },
    getSex(idCard) {
      let sexStr = '';
      if (parseInt(idCard.slice(-2, -1)) % 2 === 1) {
        sexStr = 1;
      } else {
        sexStr = 2;
      }
      return sexStr;
    },
    back() {
      this.$router.go(-1)
      /*this.$router.push({
        name: 'distributor-train-front-search',
        query: {
          fromStationCode: this.searchFormStationCode,
          toStationCode: this.searchToStationCode,
          time: moment(this.orderBookingInfoVo.trainStartDate).format('YYYY-MM-DD'),
        }
      })*/
    },
    toChooseTrain() {
      this.$router.go(-1)
      /*this.$router.push({
        name: 'distributor-train-front-search',
        query: {
          fromStationCode: this.orderBookingInfoVo.fromStationCode,
          toStationCode: this.orderBookingInfoVo.toStationCode,
          time: moment(this.orderBookingInfoVo.trainStartDate).format('YYYY-MM-DD'),
        }
      })*/
    },
    showLogin() {
      this.loginBoxShow = true
      this.$refs['loginForm'].resetFields()
    },
    loginTrain() {
      this.$refs['loginForm'].validate((formValid) => {
        if (formValid) {
          trains_order_checkAccountV2(this.loginParams).then(res => {
            if (res.code === '000000') {
              this.userInfoVo = {
                password: res.password,
                userName: res.userName,
                userId: this.userVo.id
              }
              this.isLogin = true
              this.loginBoxShow = false
              // 记录到cookie
              let userInfoVo = JSON.stringify(this.userInfoVo)
              let trainLoginParams = JSON.stringify(this.loginParams)
              // Cookies.set('userInfoVo', userInfoVo, { expires: 7 })
              // Cookies.set('trainLoginParams', trainLoginParams, { expires: 7 })
              localStorage.setItem('userInfoVo', userInfoVo)
              localStorage.setItem('trainLoginParams', trainLoginParams)
            }
          })
        }
      })
    },
    closeLogin() {
      this.loginBoxShow = false
    },
    logout() {
      this.userInfoVo = {
        password: '',
        userName: ''
      }
      this.loginParams = {
        password: '',
        userName: ''
      }
      this.isLogin = false
      // Cookies.remove('userInfoVo')
      // Cookies.remove('trainLoginParams')
      localStorage.removeItem('userInfoVo')
      localStorage.removeItem('trainLoginParams')
    },
    handlePassengersClose() {

    },
    cancelPassengerPopup() {
      trains_order_applyForCancel({orderNo: this.orderNo}).then(res => {
        this.passengersPopShow = false
        this.$router.go(-1)
      })
    },
    submitPassengerCheck() {
      this.nowMinute = 0
      trains_order_confirmTheCheck({orderNo: this.orderNo}).then(res => {
        this.passengersPopShow = false
        this.keepMinute = setInterval(() => {
          this.nowMinute++
        }, 1000)
        this.orderBookingPolling()
      })
    },
    handleWarningClose() {
      trains_order_applyForCancel({orderNo: this.orderNo}).then(res => {
        this.warningPopShow = false
        this.$router.go(-1)
      })
    },
    handleWarningSubmit() {
      this.warningPopShow = false
      this.$router.push({
        name: 'distributor-train-front-pay',
        query: {
          orderNo: _this.orderNo,
          type: 'reserve',
        }
      })
    },
  }
}
/*
* export default {
  data () {
    return {}
  },
  components: {},
  created () {
  },
  mounted () {},
  activated () {
  },
  deactivated() {},
  destroyed () {
  },
  watch: {},
  computed: {},
  filters: {},
  methods: {
  }
}
*/
