// trains_order_orderBookingPolling 预定接口结果给前段轮询

const __request = require(`@/lib/data-service/trains/__request/__request_contentType_json`);

// 接口文档地址：
export default function trains_order_orderBookingPolling(pParameter) {
  let params = {}
  if (!pParameter) pParameter = {};
  // const orderNo = pParameter.orderNo || ''
  params.method = 'POST'
  params.urlSuffix = '/trains/order/orderBookingPolling'
  params.data = pParameter
  return new Promise(function(resolve, reject) {
    resolve(__request(params))
  })
}

