const Q = require(`q`);
const _ = require(`underscore`);
const __request = require(`./__request`);

// 接口文档地址：http://192.168.0.15:8080/doc.html#/所有接口/tour-order-controller/getTravelAccountUsingPOST
const web_common_tourOrder_getTravelAccount = (pParameter) => {
  if (!pParameter) pParameter = {};
  const userId = pParameter.userId || '';
  const p = {
    method: 'post',
    urlSuffix: 'web/common/tourOrder/getTravelAccount',
    data: {
      userId,
    }
  };
  return Q.when()
    .then(function () {
      return __request(p);
    })
    .then(function (data) {
      return Q.resolve(data);
    })
    .catch(function (ex) {
      console.error(ex);
      if (ex) {
        if (ex.stack) {
          console.error(ex.stack);
        }
      }
      return Q.reject(ex);
    })
    ;

};
module.exports = web_common_tourOrder_getTravelAccount;
