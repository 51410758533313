// web_common_getUserMsg 根据token获取用户信息

const __request = require(`./__request/__request_contentType_form`);

// 接口地址： http://192.168.0.15:8080/doc.html#/yinzhilv_travel/login-controller/getUserMsgUsingPOST

export default function web_common_getUserMsg(pParameter) {
  if (!pParameter) pParameter = {};
  let params = {}
  params.method = 'POST'
  params.urlSuffix = '/web/common/getUserMsg'
  params.data = pParameter
  return new Promise(function(resolve, reject) {
    resolve(__request(params))
  })
}

