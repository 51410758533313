// trains_order_orderBookingCheckout 订单预定校验

const __request = require(`@/lib/data-service/trains/__request/__request_contentType_json`);

// 接口文档地址：
export default function trains_order_orderBookingCheckout(pParameter) {
  let params = {}
  if (!pParameter) pParameter = {};
  //const expressInfoVo = pParameter.expressInfoVo || ''
  //const insuranceInfoVos = pParameter.insuranceInfoVos || ''
  //const orderBookingInfoVo = pParameter.orderBookingInfoVo || ''
  //const trainsOrderPassengers = pParameter.trainsOrderPassengers || ''
  //const userInfoVo = pParameter.userInfoVo || ''
  params.method = 'POST'
  params.urlSuffix = '/trains/order/orderBookingCheckout'
  params.data = pParameter
  return new Promise(function(resolve, reject) {
    resolve(__request(params))
  })
}

